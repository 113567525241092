.index-list {
  padding: 0 3rem;
  height: calc(100vh - 279px);
}
.index-list .row-item:first-child {
  margin-top: 16px;
}
.col-head {
  display: flex;
  justify-content: space-between;
  padding-right: 40px;
}
.tools {
  width: 50%;
}
.index-head,
.individual {
  width: 75%;
}
.row-item {
  width: 100%;
}
.individual {
  max-height: 82px;
  position: relative;
}
/*# sourceMappingURL=src/pages/sdc/list/index.css.map */