



































































































































.container
  display inline-block
.labelled
  display flex
  flex-direction column
  justify-content center
  align-items center
  .q-btn
    display block
    width 182px
