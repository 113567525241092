.container {
  display: inline-block;
}
.labelled {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.labelled .q-btn {
  display: block;
  width: 182px;
}
/*# sourceMappingURL=src/pages/sdc/backoffice-meta-actions.css.map */