.avatar {
  margin-right: 15px;
}
h4,
h6 {
  display: inline-block;
  vertical-align: middle;
}
h6 {
  text-transform: uppercase;
}
.user-meta {
  margin-left: 70px;
  line-height: 1.5;
}
.member-info-list {
  width: 30%;
  position: relative;
  z-index: 200;
}
.top-sheet {
  padding: 1.6rem 2rem;
  padding-bottom: 0;
  position: relative;
  z-index: 150;
  min-height: 135px;
}
.q-tabs {
  position: relative;
  z-index: 100;
}
.tab-body {
  height: calc(100vh - 233px);
  overflow: auto;
}
/*# sourceMappingURL=src/pages/sdc/view/index.css.map */