.create-backoffice .modal-content {
  width: 22%;
  min-width: 300px;
}
.create-backoffice .modal-scroll {
  max-height: 500px !important;
}
.buttons {
  padding: 10px 16px;
}
/*# sourceMappingURL=src/pages/sdc/list/staff-create.css.map */