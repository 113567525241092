
























































































.tcell
  display: flex;
  align-items: center;
  justify-content: flex-start;

.avatar
  display none
  margin-right 15px

.staff-menu
  position: absolute;
  top: 0px;
  right: 12px;

.actions
  height 50px
.actions > *
  margin-top 8px

.staff
  position relative
  width 77%

.small-staff-list
  .staff
    width 96%
  .small-hide
    display none
  .staff
    max-height 70px

@media screen and (min-width: 930px)
  .avatar
    display block

